<template>
    <div class="full-height-layout fill" v-if="carrierData">
        <mercur-card class="carrier-edit">
            <h2 class="font-weight-normal">Edit carrier</h2>
            <form @submit.prevent="saveCarrier">
                <mercur-tabs :active-tab.sync="activeTab">
                    <mercur-tab title="General" id="general">
                        <mercur-input
                            v-model.trim="$v.carrierData.carrierName.$model"
                            :disabled="editCarrierLoading || loading"
                            required
                            :class="{'form-invalid': $v.carrierData.carrierName.$error}">
                            Carrier name
                            <template slot="note">
                                <span class="form-error" v-if="!$v.carrierData.carrierName.$error">Carrier name is required</span>
                            </template>
                        </mercur-input>
                    </mercur-tab>
                    <mercur-tab title="Delivery days" id="deliveryDays">
                        <working-days-fields :show-title="false" v-model="carrierData.deliveryDays" :enable-cutoff-time="true"></working-days-fields>
                    </mercur-tab>
                    <mercur-tab title="Add facilities" id="facilities">
                        <ag-grid-vue
                            class="ag-grid fill ag-theme-material border"
                            :gridOptions="gridOptions"
                            @grid-ready="onGridReady"
                            :columnDefs="columnDefs"
                            :defaultColDef="defaultColDef"
                            :suppressRowClickSelection="true"
                            :enableRangeSelection="false"
                            :autoGroupColumnDef="autoGroupColumnDef"
                            :rowData="facilities"
                            rowSelection="multiple"
                            row-model-type="clientSide"
                            :suppressCellSelection="true"
                            :groupSelectsChildren="true"
                            @selection-changed="onSelectionChanged"
                        ></ag-grid-vue>
                    </mercur-tab>
                </mercur-tabs>
                <mercur-button class="btn btn-raised btn-yellow text-uppercase" :disabled="editCarrierLoading || loading" type="submit">Save</mercur-button>
            </form>
        </mercur-card>
    </div>
</template>

<script>
import CONFIG from '@root/config'
import WorkingDaysFields from '@/components/WorkingDaysFields'
import { AgGridVue } from 'ag-grid-vue'
import 'ag-grid-enterprise'
import { required } from 'vuelidate/lib/validators'

export default {
    name: 'CarrierEdit',
    components: { WorkingDaysFields, AgGridVue },
    data () {
        return {
            carrierData: null,
            editCarrierLoading: false,
            loading: false,
            columnDefs: null,
            defaultColDef: null,
            activeTab: 'general',
            gridOptions: null,
            facilitiesToAdd: [],
            autoGroupColumnDef: null,
            facilities: null,
        }
    },

    validations: {
        carrierData: {
            carrierName: {
                required,
            },
        },
    },

    beforeMount () {
        this.gridOptions = {
        }

        this.columnDefs = [
            {
                field: 'supplierId',
                valueFormatter: ({ data, node }) => {
                    if (typeof data === 'undefined') {
                        if (node && node.allLeafChildren[0].data.supplier.supplierName) {
                            return node.allLeafChildren[0].data.supplier.supplierName
                        }
                    }

                    return ''
                },
                hide: true,
                rowGroupIndex: 0,
            },
            {
                headerName: 'Address',
                valueGetter: ({ data }) => {
                    if (!data) {
                        return ''
                    }
                    return `${data.address} ${data.houseNumber} ${data.zipCode} ${data.city}`
                },
            },
            {
                headerName: 'Country',
                field: 'country',
            },
        ]
        this.autoGroupColumnDef = {
            headerName: 'Supplier / location',
            field: 'locationName',
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            cellRenderer: 'agGroupCellRenderer',
            cellRendererParams: { checkbox: true },
        }

        this.defaultColDef = {
            editable: false,
            enableRowGroup: false,
            enablePivot: false,
            enableValue: true,
            sortable: true,
            resizable: true,
            filter: true,
        }
    },

    methods: {
        onGridReady (params) {
            this.gridApi = params.api
            this.gridColumnApi = params.columnApi

            setTimeout(() => {
                params.api.sizeColumnsToFit()
            }, 100)
            window.addEventListener('resize', () => {
                this.gridApi.sizeColumnsToFit()
            })
        },
        onModelUpdated (params) {
            setTimeout(() => {
                params.api.sizeColumnsToFit()
            }, 50)
        },
        onSelectionChanged () {
            this.facilitiesToAdd = this.gridApi.getSelectedNodes().filter(node => !node.group).map(({ data }) => data.locationId)
        },

        saveCarrier () {
            const params = {
                ...this.carrierData,
                facilitiesToAdd: this.facilitiesToAdd,
            }

            const url = CONFIG.API.ROUTES.CARRIERS.UPDATE.replace('{carrierId}', this.$route.params.carrierId)

            this.editCarrierLoading = true
            this.addJob(url)
            this.$api.post(url, params).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: `Carrier was saved`,
                })
                this.$router.push({
                    name: 'CarriersOverview',
                })
            }).catch(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: `Saving carrier failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.editCarrierLoading = false
                this.finishJob(url)
            })
        },
    },

    created () {
        const getCarrierUrl = CONFIG.API.ROUTES.CARRIERS.GET.replace('{carrierId}', this.$route.params.carrierId)

        this.loading = true
        this.addJob(getCarrierUrl)
        this.$api.get(getCarrierUrl).then(({ data }) => {
            this.carrierData = data.data
        }).catch(({ data }) => {
            this.$root.$emit('notification:global', {
                message: `Getting carrier failed. Please try again.`,
                type: 'error',
                errors: data,
            })
        }).finally(() => {
            this.finishJob(getCarrierUrl)
            this.loading = false
        })

        this.$store.dispatch('suppliers/fetchLocations').then(() => {
            this.facilities = Object.freeze(
                this.$store.getters['suppliers/getLocations'].filter(location => location.locationType === 'FACILITY').map(location => {
                    return {
                        ...location,
                    }
                })
            )
        })
    },
}
</script>
